import React, { useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Navbar from "../components/Navbar/Navbar"
import Evento from "../components/Evento/Evento"
import Separador from "../components/Separador/Separador"

import LogoCofNorte2020 from "../images/logos/cof-norte2020.svg"
import LogoCofPortugal2020 from "../images/logos/cof-portugal2020.svg"
import LogoCofUe from "../images/logos/cof-ue.svg"
import LogoOrgCmBar from "../images/logos/org-cmbar.svg"
import LogoOrgCmBra from "../images/logos/org-cmbra.svg"
import LogoOrgCmE from "../images/logos/org-cme.svg"
import LogoOrgE2020 from "../images/logos/org-e2020.svg"

const Index = ({ data }) => {
  const [lang, setLang] = useState("Pt")

  const blocos = data.allWpBloco.nodes

  const infoGeral = data.wpGeral.GeralGQL

  const setColor = municipio => {
    let backColor
    switch (municipio) {
      case "Barcelos":
        backColor = infoGeral.corDeBarcelos
        break
      case "Braga":
        backColor = infoGeral.corDeBraga
        break
      case "Esposende":
        backColor = infoGeral.corDeEsposende
        break
    }
    return backColor
  }

  const dateNotPassed = d => {
    let eventDate = Date.parse(d + " 2021")
    let todayDate = Date.now()
    return eventDate > todayDate
  }

  const getPTDate = d => {
    let day = d.slice(0, -3)
    let month = d.slice(-3)
    let monthPT

    switch (month) {
      case "Aug":
        monthPT = "Ago"
        break
      case "Sep":
        monthPT = "Set"
        break
      default:
        monthPT = month
    }

    return day + monthPT
  }

  const heroVideoURL = infoGeral.video.mediaItemUrl
  const siteTitle = lang === "Pt" ? "Do Ferro Ao Ouro" : "From Iron to Gold"
  const heroTitle =
    lang === "Pt" ? (
      <h1 className="hero__title">
        Do Ferro <br />
        Ao Ouro
      </h1>
    ) : (
      <h1 className="hero__title">
        From Iron <br />
        To Gold
      </h1>
    )
  const heroSubTitle =
    lang === "Pt" ? "Uma Viagem pela História" : "A Journey Through History"
  const newsletterText =
    lang === "Pt" ? "Subscrever Newsletter →" : "Subscribe Newsletter →"
  const footerNote =
    lang === "Pt"
      ? `A operação "Do Ferro ao Ouro" foi apresentada à Autoridade de Gestão do NORTE 2020 - Programa Operacional Regional do Norte, no âmbito do Aviso n.º 14-2020-25, sendo co-financiada no âmbito do Programa Operacional Norte 2020, Portugal 2020 e Fundo Europeu de Desenvolvimento Regional (FEDER).`
      : `The operation "From Iron to Gold" was presented to the Managing Authority of NORTE 2020 - Northern Regional Operational Program No. 14-2020-25, under the Norte 2020 Operational Program, Portugal 2020 and the European Regional Development Fund (ERDF).`
  const footerTitleCOFText =
    lang === "Pt" ? "Co-financiado por" : "Co-financed by"
  const footerTitleORGText = lang === "Pt" ? "Organização" : "Organization"

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Do Ferro ao Ouro</title>
        <meta name="description" content="Uma Viagem pela História" />
        <link rel="canonical" href="https://do-ferro-ao-ouro.pt" />
      </Helmet>

      <Navbar
        langSelectorClickHandler={setLang}
        lang={lang}
        siteTitle={siteTitle}
      />
      <section className="hero">
        <div className="hero__body">
          <div className="bg-white-front">
            <div className="main-container">
              {heroTitle}
              <span className="hero__sub-title">{heroSubTitle}</span>
              <div className="hero__cities">
                <p>Barcelos</p>
                <p>Esposende</p>
                <p>Braga</p>
              </div>
            </div>
          </div>
          <div className="hero__video">
            <video src={heroVideoURL + "#t=0.1"} controls></video>
          </div>
          <div className="bg-black">
            <div className="main-container">
              <div className="hero__description">
                <span>{infoGeral[`descricao${lang}`]}</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blocos">
        {blocos.map((bloco, blocoIndex) => {
          if (bloco.BlocoGQL?.tipoDeBloco === "Evento") {
            const municipio = bloco.BlocoGQL.eventoInfo.municipio
            const tipo = bloco.BlocoGQL.eventoInfo[`tipo${lang}`].join(" / ")
            const titulo = bloco.BlocoGQL.eventoInfo[`titulo${lang}`]
            const hora = bloco.BlocoGQL.eventoInfo.hora
            const data =
              lang == "EN"
                ? bloco.BlocoGQL.eventoInfo.data
                : getPTDate(bloco.BlocoGQL.eventoInfo.data)

            const subtitulo = bloco.BlocoGQL.eventoInfo[`subtitulo${lang}`]
            const localizacao = bloco.BlocoGQL.eventoInfo[`localizacao${lang}`]
            const descricao = bloco.BlocoGQL.eventoInfo[`descricao${lang}`]
            const maisInfoEsquerda =
              bloco.BlocoGQL.eventoInfo[`maisInfoEsquerda${lang}`]

            const maisInfoDireita =
              bloco.BlocoGQL.eventoInfo[`maisInfoDireita${lang}`]

            const color = setColor(bloco.BlocoGQL.eventoInfo.municipio)

            // if (dateNotPassed(data)) {
            return (
              <Evento
                key={blocoIndex}
                municipio={municipio}
                tipo={tipo}
                titulo={titulo}
                hora={hora}
                data={data}
                subtitulo={subtitulo}
                localizacao={localizacao}
                descricao={descricao}
                maisInfoEsquerda={maisInfoEsquerda}
                maisInfoDireita={maisInfoDireita}
                color={color}
                lang={lang}
              ></Evento>
            )
            // }
          } else {
            const titulo = bloco.BlocoGQL.separadorInfo[`titulo${lang}`]
            const data = bloco.BlocoGQL.separadorInfo.dataDeInicio
            const datasEmQueDecorre =
              bloco.BlocoGQL.separadorInfo.datasEmQueDecorre
            const descricao = bloco.BlocoGQL.separadorInfo[`descricao${lang}`]
            const municipio = bloco.BlocoGQL.separadorInfo.municipio
            const pecasRelacionadas =
              bloco.BlocoGQL.separadorInfo.pecasRelacionadas

            const color = setColor(municipio)

            return (
              <Separador
                key={blocoIndex}
                titulo={titulo}
                data={data}
                datasEmQueDecorre={datasEmQueDecorre}
                descricao={descricao}
                municipio={municipio}
                pecasRelacionadas={pecasRelacionadas}
                color={color}
                lang={lang}
              ></Separador>
            )
          }
        })}
      </section>

      <div className="top-border">
        <footer className="main-footer main-container">
          <div className="main-footer__up">
            <div>
              <span className="main-footer__social-media">
                <a
                  href="https://www.facebook.com/doferroaoouro"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook,{" "}
                </a>
                <a
                  href="https://www.instagram.com/doferroaoouro"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </span>
            </div>
            <span className="main-footer__newsletter">
              <a
                href="https://mailchi.mp/6908b08030e8/li2pubyfup"
                target="_blank"
                rel="noopener noreferrer"
              >
                {newsletterText}
              </a>
            </span>
          </div>
          <div className="main-footer__mid">
            <div className="logos-area">
              <div className="logos-container">
                <span className="footer__logos-title allcaps">
                  {footerTitleCOFText}
                </span>
                <div className="logos">
                  <img
                    src={LogoCofNorte2020}
                    alt="Norte 2020 Logo"
                    className="footer__logo"
                  />
                  <img
                    src={LogoCofPortugal2020}
                    alt="Portugal 2020 Logo"
                    className="footer__logo"
                  />
                  <img
                    src={LogoCofUe}
                    alt="European Union Logo"
                    className="footer__logo"
                  />
                </div>
              </div>
              <div className="logos-container">
                <span className="footer__logos-title allcaps">
                  {footerTitleORGText}
                </span>
                <div className="logos">
                  <img
                    src={LogoOrgCmE}
                    alt="Esposende Municipality Logo"
                    className="footer__logo"
                  />
                  <img
                    src={LogoOrgCmBar}
                    alt="Barcelos Municipality Logo"
                    className="footer__logo"
                  />
                  <img
                    src={LogoOrgCmBra}
                    alt="Braga Municipality Logo"
                    className="footer__logo"
                  />
                  <img
                    src={LogoOrgE2020}
                    alt="Esposende 2020 Logo"
                    className="footer__logo"
                  />
                </div>
              </div>
            </div>
            <a href="https://www.oof.pt/" className="main-footer__link">
              Design by OOF
            </a>
          </div>
          <div className="main-footer__down">
            <p className="footer-note">{footerNote}</p>
          </div>
        </footer>
      </div>
    </main>
  )
}

export const pageQuery = graphql`
  query AllWordpressData {
    wpGeral {
      GeralGQL {
        descricaoPt
        descricaoEn
        corDeEsposende
        corDeBraga
        corDeBarcelos
        video {
          mediaItemUrl
        }
      }
    }
    allWpBloco(sort: { order: ASC, fields: date }) {
      nodes {
        BlocoGQL {
          eventoInfo {
            data
            descricaoEn
            descricaoPt
            hora
            localizacaoEn
            localizacaoPt
            maisInfoDireitaEn
            maisInfoDireitaPt
            maisInfoEsquerdaPt
            maisInfoEsquerdaEn
            municipio
            subtituloEn
            subtituloPt
            tipoEn
            tipoPt
            tituloEn
            tituloPt
          }
          separadorInfo {
            dataDeInicio
            datasEmQueDecorre
            descricaoEn
            descricaoPt
            municipio
            tituloEn
            pecasRelacionadas {
              ... on WpPeca {
                PecaGQL {
                  descricaoEn
                  descricaoPt
                  localEMorada
                  maisInfoEn
                  maisInfoPt
                  tituloEn
                  tituloPt
                  fotos {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1000, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
            tituloPt
          }
          tipoDeBloco
        }
        date
      }
    }
  }
`

export default Index
