import React, { useState } from "react"
import AnimateHeight from "react-animate-height"
import parse from "html-react-parser"

export default function Evento(props) {
  const [height, setHeight] = useState(0)

  const toggleHeight = () => {
    setHeight(height === 0 ? "auto" : 0)
  }

  return (
    <div
      className="top-border"
      style={{
        backgroundColor: height === "auto" ? "black" : props.color,
        borderTopColor: height === 0 ? "black" : props.color,
      }}
    >
      <div className="main-container">
        <div
          className="evento__container"
          style={{ color: height === "auto" ? props.color : "black" }}
        >
          <header className="evento__header" onClick={toggleHeight}>
            <h3 className="text-left">{props.municipio}</h3>
            <h3 className="text-center">{parse(props.tipo)}</h3>
            <h3 className="text-center evento__header__titulo">
              {parse(props.titulo)}
            </h3>
            <span className="text-right">{parse(props.hora)}</span>
            <span className="text-right">{parse(props.data)}</span>
          </header>

          <AnimateHeight duration={400} height={height}>
            <div className="evento__body">
              <div className="evento__body__up-left">
                <span>{parse(props.subtitulo)}</span>
                <div className="up-left__right">
                  <span>
                    {props.lang === "Pt" ? "Localização" : "Location"}
                  </span>
                  <br />
                  <span>{parse(props.localizacao)}</span>
                </div>
              </div>
              <div className="evento__body__up-right normal-text">
                {parse(props.descricao)}
              </div>
              <span className="evento__body__down-left text-right">
                {parse(props.maisInfoEsquerda)}
              </span>
              <span className="evento__body__down-right">
                {parse(props.maisInfoDireita)}
              </span>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  )
}
