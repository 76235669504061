import React from "react"

import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Peca(props) {
  const titulo = props.titulo
  const localEMorada = props.localEMorada
  const maisInfo = props.maisInfo
  const descricao = props.descricao
  const fotos = props.fotos

  return (
    <article
      className="peca__container"
      style={{
        color: props.color,
      }}
    >
      <header className="peca__header">
        <h4 className="peca__header__title">{titulo}</h4>
      </header>
      <div className="peca__body">
        <div className="peca__body__image">
          <GatsbyImage
            alt={titulo}
            image={fotos[0].localFile.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="peca__body__info">
          <span className="text-left">{parse(localEMorada)}</span>
          <span className="text-right">{parse(maisInfo)}</span>
        </div>
        <div className="peca__body__description text-left normal-text">
          {parse(descricao)}
        </div>
      </div>
    </article>
  )
}
