import React, { useState } from "react"
import parse from "html-react-parser"
import AnimateHeight from "react-animate-height"
import Peca from "../Peca/Peca"

export default function Separador(props) {
  const pecasRelacionadas = props.pecasRelacionadas

  const [height, setHeight] = useState(0)
  const toggleHeight = () => {
    setHeight(height === 0 ? "auto" : 0)
  }

  const [currentSlider, setCurrentSlider] = useState(0)

  const nextSlider = () => {
    currentSlider < pecasRelacionadas.length - 1 &&
      setCurrentSlider(currentSlider + 1)
  }
  const previousSlider = () => {
    currentSlider > 0 && setCurrentSlider(currentSlider - 1)
  }

  return (
    <div
      className="separador__container top-border"
      style={{
        backgroundColor: props.color,
      }}
    >
      <header className="separador__header big-text main-container">
        <span>{props.municipio}</span>
        <span>{props.datasEmQueDecorre}</span>
      </header>
      <div className="separador__body text-center">
        <div className="main-container">
          <div className="sqz">
            <h2>{parse(props.titulo)}</h2>
            <div className="separador__body__description">
              {parse(props.descricao)}
            </div>
          </div>
        </div>

        {/* <button
            className="separador__body__button separador__body__button--closed --btn-unset"
            onClick={toggleHeight}
          >
            {height !== 0
              ? props.lang === "Pt"
                ? "Fechar Roteiro ↑"
                : "Close Road Map ↑"
              : props.lang === "Pt"
              ? "Abrir Roteiro ↓"
              : "Open Road Map ↓"}
          </button> */}
        {height !== 0 ? (
          props.lang === "Pt" ? (
            <button
              className="separador__body__button separador__body__button--open --btn-unset"
              onClick={toggleHeight}
            >
              Fechar Roteiro ↑
            </button>
          ) : (
            <button
              className="separador__body__button separador__body__button--open --btn-unset"
              onClick={toggleHeight}
            >
              Fechar Roteiro ↑
            </button>
          )
        ) : props.lang === "Pt" ? (
          <button
            className="separador__body__button separador__body__button--closed --btn-unset"
            onClick={toggleHeight}
          >
            Abrir Roteiro ↓
          </button>
        ) : (
          <button
            className="separador__body__button separador__body__button--closed --btn-unset"
            onClick={toggleHeight}
          >
            Open Road Map ↓
          </button>
        )}

        <AnimateHeight
          className="pecas-container"
          duration={400}
          height={height}
        >
          <div
            className="peca__header__number big-text"
            style={{ color: props.color }}
          >
            <button className="--btn-unset" onClick={previousSlider}>
              ←
            </button>
            <span> {currentSlider + 1}</span>
            <span> / </span>
            <span>{pecasRelacionadas.length} </span>
            <button className="--btn-unset" onClick={nextSlider}>
              →
            </button>
          </div>
          <Peca
            key={currentSlider}
            height={height}
            color={props.color}
            lang={props.lang}
            titulo={
              pecasRelacionadas[currentSlider].PecaGQL[`titulo${props.lang}`]
            }
            fotos={pecasRelacionadas[currentSlider].PecaGQL.fotos}
            descricao={
              pecasRelacionadas[currentSlider].PecaGQL[`descricao${props.lang}`]
            }
            localEMorada={pecasRelacionadas[currentSlider].PecaGQL.localEMorada}
            maisInfo={
              pecasRelacionadas[currentSlider].PecaGQL[`maisInfo${props.lang}`]
            }
          />
          <footer className="peca__footer main-container">
            <div className="peca__footer__anterior allcaps">
              <span
                className="peca__footer__anterior__number big-text"
                style={{ color: props.color }}
              >
                {currentSlider > 0 && (
                  <>
                    <button className="--btn-unset" onClick={previousSlider}>
                      ←<span> {currentSlider}</span>
                      <span>/</span>
                      <span>{pecasRelacionadas.length} </span>
                      <span className="peca__footer__title">
                        {parse(
                          pecasRelacionadas[currentSlider - 1].PecaGQL[
                            `titulo${props.lang}`
                          ]
                        )}
                      </span>
                    </button>
                  </>
                )}
              </span>
            </div>
            <div className="peca__footer__proximo allcaps">
              <span
                className="peca__footer__proximo__number big-text"
                style={{ color: props.color }}
              >
                {currentSlider + 1 <= pecasRelacionadas.length - 1 && (
                  <>
                    <button className="--btn-unset" onClick={nextSlider}>
                      <span className="peca__footer__title">
                        {parse(
                          pecasRelacionadas[currentSlider + 1].PecaGQL[
                            `titulo${props.lang}`
                          ]
                        )}
                      </span>
                      <span> {currentSlider + 2}</span>
                      <span>/</span>
                      <span>{pecasRelacionadas.length} </span>→
                    </button>
                  </>
                )}
              </span>
            </div>
          </footer>
        </AnimateHeight>
      </div>
    </div>
  )
}
