import React from "react"

export default function Navbar(props) {
  return (
    <>
      <div className="navbar__background">
        <span className="navbar__title allcaps">{props.siteTitle}</span>
      </div>
      <div className="navbar">
        <div className="navbar__lang-selector">
          <span
            className={
              props.lang === "Pt" ? "navbar__lang-selector--active" : undefined
            }
            onClick={() => props.langSelectorClickHandler("Pt")}
          >
            PT
          </span>
          <span className="separator"> / </span>
          <span
            className={
              props.lang === "En" ? "navbar__lang-selector--active" : undefined
            }
            onClick={() => props.langSelectorClickHandler("En")}
          >
            EN
          </span>
        </div>
        <span className="navbar__date">JUN-SET '21</span>
      </div>
    </>
  )
}
